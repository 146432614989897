/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "gatsby";
// import { window } from 'ssr-window';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Tooltip } from "@material-ui/core";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/uikit/CustomDropdown/CustomDropdown.js";
// import Button from "components/uikit/CustomButtons/Button.js";

import { Button } from "@material-ui/core";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const {categories} = props;

  return (
    <List className={classes.list}>
      {categories.map((category,key)=> 
        <ListItem className={classes.listItem} key={key}>
        <Button
          href={`/categories/${category.slug}`}
          className={classes.navLink}
        >
          {category.name}
        </Button>
      </ListItem>)
      }
    </List>
  );
}

/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "gatsby";
// import { window } from 'ssr-window';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Tooltip } from "@material-ui/core";



// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/uikit/CustomDropdown/CustomDropdown.js";
// import Button from "components/uikit/CustomButtons/Button.js";

import { Button } from "@material-ui/core";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import NavLink from "components/atoms/NaviLink";
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const {categories} = props;
  const [viewCateories, setViewCategories] = React.useState([]);

  React.useEffect(()=>{
    setViewCategories(categories.slice(0, 4))
  }, [categories]);

  return (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="カテゴリー"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/categories/イベント情報" className={classes.dropdownLink}>
              イベント情報
            </Link>,
            <a
              href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"
              target="_blank"
              className={classes.dropdownLink}
            >
              Documentation
            </a>
          ]}
        />
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Button　target="_blank" href={`http://www.kazi.co.jp/`} className={classes.navLink}>
          <NavLink>舵社HP</NavLink>
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button　target="_blank" href={`https://www.seaplaza.jp/`} className={classes.navLink}>
          <NavLink>KAZIオンラインショップ</NavLink>
        </Button>
      </ListItem>

      {/* <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
        >
          お問い合わせ
        </Button>
      </ListItem> */}
      {/* {viewCateories.map((category,key)=> 
        <ListItem className={classes.listItem} key={key}>
        <Button
          href={`/categories/${category.name}`}
          className={classes.navLink}
        >
          {category.name}
        </Button>
      </ListItem>)
      } */}
      {/* <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-twitter"
            title="Follow us on twitter"
            placement={window && window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              href="https://twitter.com/CreativeTim?ref=creativetim"
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
          </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="公式Facebookページ"
          placement={window && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/onlineboatshow/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="公式インスタグラムページ"
          placement={window && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  );
}

import styled from "styled-components";
import { getCfUrl } from "settings/storageSettings";

const Cover = styled.div`
  position: relative;
  width: 100%;
  min-width: 320px;
  background-size: cover;
  padding-top: 28.3%;
  background-position: center center;
  background-image: url(${(props) =>
    props.isS3 ? getCfUrl(props.src) : props.src});
  /* &:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background: rgba(0, 0, 0, 0.4);
  } */
`;

export default Cover;

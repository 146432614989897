import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Layout from "components/layouts/Common";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Category from "components/pages/front/Category";
import { getCfUrl } from "settings/storageSettings";

type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { articles, category, categories, footers } = props.pageContext;

  return (
    <>
      <GatsbySeo
        title={`${category?.name} 舵オンライン │ 船遊びの情報サイト`}
        description={category?.description}
        openGraph={{
          url: `https://www.kazi-online.com/categories/${category?.slug}`,
          title: `${category?.name} 舵オンライン │ 船遊びの情報サイト`,
          description: category?.description,
          site_name: "舵オンライン │ 船遊びの情報サイト",
          type: "website",
          images: [
            {
              url:
                category?.mainImage && category?.mainImage?.url
                  ? getCfUrl(category?.mainImage.url)
                  : "",
              alt: category?.name,
            },
          ],
        }}
      />
      <Helmet>
        <title>{category?.name} 舵オンライン │ 船遊びの情報サイト</title>
      </Helmet>
      <Layout footers={footers}>
        <Category
          category={category}
          categories={categories}
          articles={articles}
        />
      </Layout>
    </>
  );
}

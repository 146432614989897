import styled from "styled-components";

const NavLink = styled.span`
  border-right: solid 1px rgba(0, 0, 0, 0.5);
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  padding-right: 12px;
`;

export default NavLink;
